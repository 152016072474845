<template>
  <div>
    <router-view class="main-background"/>
  </div>
</template>

<script>
import '@/styles/ground_styles.css'

export default {}
</script>

<style>
:root{
  --nav_margin: 5%;
  --nav_elements: 5;
}
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

#nav {
  position: absolute;
  margin: var(--nav_margin);
  width: calc(100% - 2*var(--nav_margin));
  margin-bottom: 5%;
  bottom: 0%;
  left: 0;
  padding: 0px;
  z-index: 100;
}

#nav a {
  font-weight: bold;
  color: #2c3e50;
}

#nav a.router-link-exact-active {
  color: #42b983;
}
</style>
